import { INIT_EMAIL_NOTIFICATIONS } from '@pixcap/ui-core/constants/user.constants';
import {
	CartItem,
	CartItemType,
	MutationTypes,
	SUBSCRIPTION,
	SUBSCRIPTION_TYPE,
	UPGRADE_PLAN_TRIGGER_TYPE,
	UserState,
} from '@pixcap/ui-core/models/store/user.interface';
import { appUtilities } from '@pixcap/ui-core/modules/appUtilities';
import { CART_ITEMS } from '@pixcap/ui-core/constants/auth.constants';

export default {
	[MutationTypes.SET_USER_ID](state: UserState, userId) {
		state.userId = userId;
	},
	[MutationTypes.UPDATE_PERSONAL_DATA](state: UserState, personalData) {
		state.personalData = personalData;
	},
	[MutationTypes.UPDATE_USER_AVATAR](state: UserState, avatarUrl) {
		state.personalData.user.avatarUrl = avatarUrl;
		state.personalData = { ...state.personalData };
	},
	[MutationTypes.UPDATE_SHOW_PIXRENDER_POPUP](state: UserState, showPixrenderPopup) {
		state.personalData.user.metadata.showPixrenderPopup = showPixrenderPopup;
		state.personalData = { ...state.personalData };
	},
	[MutationTypes.UPDATE_USER_ONBOARDING](state: UserState, onboarding) {
		state.personalData.user.onboarding = { ...state.personalData.user.onboarding, ...onboarding };
		state.personalData = { ...state.personalData };
	},
	[MutationTypes.UPDATE_USER_AI_ATTEMPTED](state: UserState, aiAttempted) {
		state.personalData.user.metadata.aiAttempted = aiAttempted;
		state.personalData = { ...state.personalData };
	},
	[MutationTypes.UPDATE_PROJECT_COUNT](state: UserState, projectCount) {
		state.personalData.user.projectCount = Math.max(projectCount, 0);
		state.personalData = { ...state.personalData };
	},
	[MutationTypes.TOGGLE_NO_AUTH_MODAL](state: UserState, showNoAuthModal) {
		state.showNoAuthModal = showNoAuthModal;
	},
	[MutationTypes.TOGGLE_RESET_PASSWORD_MODAL](state: UserState, showResetPasswordModal) {
		state.showResetPasswordModal = showResetPasswordModal;
	},
	[MutationTypes.SET_SUBSCRIPTION_INFO](state: UserState, subscriptionInfo) {
		state.subscriptionInfo = subscriptionInfo;
	},
	[MutationTypes.SET_CREDITS_INFO](state: UserState, creditsInfo: { monthlyLimit: number; monthlyUsed: number; topped: number }) {
		state.personalData.user.limits.credits = creditsInfo;
	},
	[MutationTypes.SET_REFERRALS_INFO](state: UserState, referralsInfo) {
		state.referralsInfo = referralsInfo;
	},
	[MutationTypes.SET_USER_NOTIFICATIONS](state: UserState, notifications) {
		state.notifications = notifications;
	},
	[MutationTypes.PUSH_USER_NOTIFICATIONS](state: UserState, notification) {
		const previousNotifications = [...state.notifications.content];
		if (previousNotifications.findIndex((item) => item.userNotificationId === notification.userNotificationId) !== -1) {
			return;
		}
		state.notifications.content.unshift(notification);
	},
	[MutationTypes.TOGGLE_CANCEL_PLAN_MODAL](state) {
		const showCancelPlanModal = state.showCancelPlanModal;
		state.showCancelPlanModal = !showCancelPlanModal;
	},
	[MutationTypes.TOGGLE_ACCOUNT_SETTING_MODAL](state) {
		const showAccountSettingModal = state.showAccountSettingModal;
		state.showAccountSettingModal = !showAccountSettingModal;
	},
	[MutationTypes.SET_ACTIVE_MY_ACCOUNT_TAB](state: UserState, activeTab) {
		state.activeMyAccountTab = activeTab;
	},
	[MutationTypes.UPDATE_BIO](state: UserState, bio) {
		state.personalData.user.bio = bio;
	},
	[MutationTypes.UPDATE_DISPLAY_NAME](state: UserState, displayName) {
		state.personalData.user.name = displayName;
	},
	[MutationTypes.UPDATE_IMPORT_TEMPLATE_OPTION](state: UserState, openProject) {
		state.personalData.user.metadata.openProject = openProject;
		state.personalData = { ...state.personalData };
	},
	[MutationTypes.UPDATE_PAYMENT_STATUS](state: UserState, paymentStatus) {
		state.subscriptionInfo.paymentStatus = paymentStatus;
	},
	[MutationTypes.UPDATE_PROJECT_LIKES](state: UserState, payload) {
		const projectLikes = state.personalData.projectLikes;
		const { projectId, remove } = payload;
		if (remove) {
			projectLikes.splice(projectLikes.indexOf(projectId), 1);
		} else {
			projectLikes.push(projectId);
		}
	},
	[MutationTypes.TOGGLE_CROPP_AVATAR_MODAL](state: UserState, imageFile) {
		state.CroppPictureModal = imageFile;
	},
	[MutationTypes.SHOW_PORTAL_PAYMENT_STATUS_MODAL](state: UserState, portalPaymentStatus) {
		state.showPortalPaymentStatus = portalPaymentStatus;
	},
	[MutationTypes.SET_IS_LOADING_USER_INFO](state: UserState, isLoadingUserInfo) {
		state.isLoadingUserInfo = isLoadingUserInfo;
	},
	[MutationTypes.SET_UPGRADE_MODAL_STATE](state: UserState, payload?: UserState['upgradeModalState']) {
		state.upgradeModalState = payload;
	},
	[MutationTypes.SET_SHOW_TOP_UP_CREDITS_MODAL](state: UserState, showTopUpCreditsModal: UserState['showTopUpCreditsModal']) {
		state.showTopUpCreditsModal = showTopUpCreditsModal;
	},
	[MutationTypes.SET_TOPPED_CREDITS_QUANTITY_FOR_PAYMENT](state: UserState, quantity: UserState['toppedCreditsQuantityForPayment']) {
		state.toppedCreditsQuantityForPayment = quantity;
	},
	[MutationTypes.SET_SHOW_CONTACT_MODAL](state: UserState, showContactModal) {
		state.showContactModal = showContactModal;
	},
	[MutationTypes.SET_SHOW_REFERRAL_MODAL](state: UserState, showReferralModal) {
		state.showReferralModal = showReferralModal;
	},
	[MutationTypes.SET_SHOW_REFERRAL_POPOVER_MODAL](state: UserState, showReferralPopoverModal) {
		state.showReferralPopoverModal = showReferralPopoverModal;
	},
	[MutationTypes.SET_IS_SENDING_MESSAGE](state: UserState, isSendingMessage) {
		state.isSendingMessage = isSendingMessage;
	},
	[MutationTypes.SET_SELECTED_SUBSCRIPTION_FOR_PAYMENT](state: UserState, payload: SUBSCRIPTION) {
		state.selectedSubscriptionForPayment = payload;
	},
	[MutationTypes.SET_EMAIL_NOTIFICATIONS](state: UserState, payload) {
		Object.assign(state.emailNotifications, payload || INIT_EMAIL_NOTIFICATIONS);
	},
	[MutationTypes.SET_SHOW_ATTRIBUTION_EXPORT_MODAL](state: UserState, showAttributionDownloadModal) {
		state.showAttributionDownloadModal = showAttributionDownloadModal;
	},
	[MutationTypes.SET_USER_COUNTRY](state: UserState, userCountry: string) {
		state.userCountry = userCountry;
	},
	[MutationTypes.DEDUCT_CREDITS](state: UserState, amount: number) {
		if (amount < 0) {
			if (state.personalData.user.limits.credits.monthlyUsed > 0) state.personalData.user.limits.credits.monthlyUsed += amount;
			else state.personalData.user.limits.credits.topped -= amount;
		} else state.personalData.user.limits.credits.monthlyUsed += amount;
	},
	[MutationTypes.SET_IS_FETCHING_CART_ITEMS](state: UserState, isFetching: boolean) {
		state.isFetchingCartItems = isFetching;
	},
	[MutationTypes.SET_CART_ITEMS](state: UserState, cartItems: CartItem[]) {
		state.cartItems = cartItems;
	},
	[MutationTypes.SET_CART_ITEMS_ID](state: UserState, cartItemsId: { itemId: string; itemType: CartItemType }[]) {
		state.cartItemsId = cartItemsId;
	},
	[MutationTypes.ADD_CART_ITEMS_ID](state: UserState, payload: { itemId: string; itemType: CartItemType }) {
		const { itemId, itemType } = payload;
		if (state.cartItemsId.find((item) => item.itemId === itemId)) return;
		state.cartItemsId.push({ itemId, itemType });
		appUtilities.$services.localStorageManager.write(CART_ITEMS, state.cartItemsId);
	},
	[MutationTypes.REMOVE_CART_ITEMS_ID](state: UserState, itemId: string) {
		state.cartItemsId = state.cartItemsId.filter((item) => item.itemId !== itemId);
		appUtilities.$services.localStorageManager.write(CART_ITEMS, state.cartItemsId);
	},
	[MutationTypes.CLEAR_CART_ITEMS](state: UserState) {
		state.cartItemsId = [];
		state.cartItems = [];
		appUtilities.$services.localStorageManager.remove(CART_ITEMS);
	},
	[MutationTypes.SET_SHOW_CART_PAYMENT_METHOD_MODAL](
		state: UserState,
		payload: { isOpen: boolean; items?: { itemId: string; itemType: CartItemType }[] }
	) {
		state.showCartPaymentMethodModal = payload;
	},
	[MutationTypes.SET_SHOW_CART_PAYMENT_STATUS_POPOVER_MODAL](state: UserState, cartPaymentStatus) {
		state.showCartPaymentStatusPopoverModal = cartPaymentStatus;
	},
	// Purchase History
	[MutationTypes.SET_IS_FETCHING_PURCHASE_ITEMS](state: UserState, isFetchingPurchaseItems) {
		state.isFetchingPurchaseItems = isFetchingPurchaseItems;
	},
	[MutationTypes.SET_PURCHASED_ITEMS](state: UserState, payload) {
		const { items, totalPages, currentPage, refresh } = payload;
		state.purchaseItems = !refresh ? [...state.purchaseItems, ...items] : items;
		state.purchaseCurrentPage = currentPage;
		state.purchaseTotalPages = totalPages;
	},
	[MutationTypes.SET_PURCHASED_ITEMS_FILTER](state: UserState, purchaseItemFilter) {
		state.purchaseItemFilter = {
			...state.purchaseItemFilter,
			...purchaseItemFilter,
		};
	},
	[MutationTypes.RESET_PURCHASED_ITEMS](state: UserState) {
		state.purchaseItems = [];
		state.purchaseCurrentPage = 0;
		state.purchaseTotalPages = null;
	},
	[MutationTypes.SET_TEMPORARY_USER_PURCHASED_HISTORY](state: UserState, itemIds: string[]) {
		state.personalData.user.purchaseHistory = state.personalData.user.purchaseHistory.concat(itemIds);
	},
};
