import type { Dispatch, Commit } from 'vuex';
import {
	NAMESPACE,
	ActionTypes,
	MutationTypes,
	PersonalData,
	SubscriptionInfo,
	MY_ACCOUNT_TAB,
	PAYMENT_STATUS,
	ContactModalType,
	SUBSCRIPTION,
	UserState,
	EmailNotifications,
	PaymentChannel,
	SUBSCRIPTION_TYPE,
	User,
	UPGRADE_PLAN_TRIGGER_TYPE,
	ReferralsInfo,
	TemplateImportOption,
	Notification,
	PurchaseItemsFilter,
	PurchaseItem,
	CartItemType,
} from '@pixcap/ui-core/models/store/user.interface';

export const actionsWrapper = {
	async fetchUserInfo({ dispatch }: { dispatch: Dispatch }, showLoading?: boolean) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_USER_INFO}`, showLoading, { root: true });
	},
	async fetchPersonalData(
		{ dispatch }: { dispatch: Dispatch },
		isForceRefresh = false,
		ignoreSockets = false
	): Promise<{ personalData?: PersonalData; subscriptionInfo?: SubscriptionInfo }> {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_PERSONAL_DATA}`, { isForceRefresh, ignoreSockets }, { root: true });
	},
	async fetchCredits({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_CREDITS}`, null, { root: true });
	},
	async fetchSubscription({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_SUBSCRIPTION}`, null, { root: true });
	},
	async fetchReferralsInfo({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_REFERRALS_INFO}`, null, { root: true });
	},
	async fetchCountryCode({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_COUNTRY_CODE}`, null, { root: true });
	},
	async fetchNotifications({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_NOTIFICATIONS}`, null, { root: true });
	},
	async subscribeNotifications({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SUBCRIBE_NOTIFICATIONS}`, null, { root: true });
	},
	async subscribeSessionChange({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SUBSCRIBE_USER_SESSIONS}`, null, { root: true });
	},
	async subscribeAccountSubscriptionChange({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SUBSCRIBE_ACCOUNT_SUBSCRIPTION_CHANGE}`, null, { root: true });
	},
	async subscribeCreditsChange({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SUBSCRIBE_CREDITS_CHANGE}`, null, { root: true });
	},
	async subscribeRewardsChange({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SUBSCRIBE_REWARDS_CHANGE}`, null, { root: true });
	},
	async changeAvatar({ dispatch }: { dispatch: Dispatch }, avatarBlob: any) {
		return dispatch(`${NAMESPACE}/${ActionTypes.CHANGE_AVATAR}`, avatarBlob, { root: true });
	},
	async fetchTutorials({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_TUTORIALS}`, null, { root: true });
	},
	async updatePersonalData(
		{ dispatch }: { dispatch: Dispatch },
		updateDto: {
			bio?: string;
			displayName?: string;
			showPixrenderPopup?: boolean;
			onboarding?: User['onboarding'];
			aiAttempted?: boolean;
			openProject?: TemplateImportOption;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.UPDATE_PERSONAL_DATA}`, updateDto, { root: true });
	},
	async renewSubscription({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.RENEW_SUBSCRIPTION}`, null, { root: true });
	},
	async cancelSubscription({ dispatch }: { dispatch: Dispatch }, reason: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.CANCEL_SUBSCRIPTION}`, reason, { root: true });
	},
	async addCancelSubscriptionReason({ dispatch }: { dispatch: Dispatch }, reason: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.ADD_CANCEL_SUBSCRIPTION_REASON}`, reason, { root: true });
	},
	async fetchSubscriptionPaymentPortal(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			newSubscription: SUBSCRIPTION;
			channel?: PaymentChannel;
			openPaymentMethodModal?: boolean;
			redirectUrl?: string;
			promotionCode?: string;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_SUBSCRIPTION_PAYMENT_PORTAL}`, payload, { root: true });
	},
	async fetchCustomerPortal({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_CUSTOMER_PORTAL}`, null, { root: true });
	},
	async fetchInvoicesPortal({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_INVOICES_PORTAL}`, null, { root: true });
	},
	async fetchTopUpCreditsPortal(
		{ dispatch }: { dispatch: Dispatch },
		payload: { quantity: number; channel?: string; openPaymentMethodModal?: boolean }
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_TOP_UP_CREDITS_PORTAL}`, payload, { root: true });
	},
	async fetchCartCheckoutPortal({ dispatch }: { dispatch: Dispatch }, payload: { itemIds?: string[]; packIds?: string[]; channel?: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_CART_CHECKOUT_PORTAL}`, payload, { root: true });
	},
	async contactUS(
		{ dispatch }: { dispatch: Dispatch },
		payload: { name: string; email: string; message: string; isCustomPricing?: boolean; concern?: string }
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.CONTACT_US}`, payload, { root: true });
	},
	async fetchEmailNotifications({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_EMAIL_NOTIFICATIONS}`, null, { root: true });
	},
	async updateEmailNotification({ dispatch }: { dispatch: Dispatch }, payload: EmailNotifications) {
		return dispatch(`${NAMESPACE}/${ActionTypes.UPDATE_EMAIL_NOTIFICATION}`, payload, { root: true });
	},
	async fetchCartItems({ dispatch }: { dispatch: Dispatch }, payload: { manuallyFetch?: boolean; itemIds?: string[]; packIds?: string[] }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_CART_ITEMS}`, payload, { root: true });
	},
	async fetchPurchasedItems(
		{ dispatch }: { dispatch: Dispatch },
		payload: {
			refresh?: boolean;
			page: number;
			pageSize?: number;
			search?: string;
			sortBy?: string;
		}
	) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_PURCHASED_ITEMS}`, payload, { root: true });
	},
	async fetchPresignedPurchasedItem({ dispatch }: { dispatch: Dispatch }, itemId: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_PRESIGNED_PURCHASED_ITEM}`, itemId, { root: true });
	},
};

export const mutationsWrapper = {
	setUserId({ commit }: { commit: Commit }, userId: string) {
		commit(`${NAMESPACE}/${MutationTypes.SET_USER_ID}`, userId, { root: true });
	},
	updatePersonalData({ commit }: { commit: Commit }, personalData: PersonalData | null) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_PERSONAL_DATA}`, personalData, { root: true });
	},
	updateUserAvatar({ commit }: { commit: Commit }, avatarUrl: string) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_USER_AVATAR}`, avatarUrl, { root: true });
	},
	updateShowPixrenderPopup({ commit }: { commit: Commit }, showPixrenderPopup: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_SHOW_PIXRENDER_POPUP}`, showPixrenderPopup, { root: true });
	},
	updateUserOnboarding({ commit }: { commit: Commit }, onboarding: User['onboarding']) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_USER_ONBOARDING}`, onboarding, { root: true });
	},
	updateUserAiAttempted({ commit }: { commit: Commit }, aiAttempted: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_USER_AI_ATTEMPTED}`, aiAttempted, { root: true });
	},
	updateProjectCount({ commit }: { commit: Commit }, projectCount: number) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_PROJECT_COUNT}`, projectCount, { root: true });
	},
	showAuthenticationModal({ commit }: { commit: Commit }, showAuthenticationModal: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.TOGGLE_NO_AUTH_MODAL}`, showAuthenticationModal, { root: true });
	},
	showResetPasswordModal({ commit }: { commit: Commit }, showResetPasswordModal: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.TOGGLE_RESET_PASSWORD_MODAL}`, showResetPasswordModal, { root: true });
	},
	setSubscriptionInfo({ commit }: { commit: Commit }, subscriptionInfo: SubscriptionInfo) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SUBSCRIPTION_INFO}`, subscriptionInfo, { root: true });
	},
	setCreditsInfo({ commit }: { commit: Commit }, creditsInfo: { monthlyLimit: number; monthlyUsed: number; topped: number }) {
		commit(`${NAMESPACE}/${MutationTypes.SET_CREDITS_INFO}`, creditsInfo, { root: true });
	},
	setReferralsInfo({ commit }: { commit: Commit }, referralsInfo: ReferralsInfo) {
		commit(`${NAMESPACE}/${MutationTypes.SET_REFERRALS_INFO}`, referralsInfo, { root: true });
	},
	setUserNotifications(
		{ commit }: { commit: Commit },
		notifications: { content: Notification[]; page: number; size: number; totalItems: number; totalPages: number }
	) {
		commit(`${NAMESPACE}/${MutationTypes.SET_USER_NOTIFICATIONS}`, notifications, { root: true });
	},
	pushUserNotifications({ commit }: { commit: Commit }, notification: Notification) {
		commit(`${NAMESPACE}/${MutationTypes.PUSH_USER_NOTIFICATIONS}`, notification, { root: true });
	},
	toggleCancelPlanModal({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.TOGGLE_CANCEL_PLAN_MODAL}`, undefined, { root: true });
	},
	toggleAccountSettingModal({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.TOGGLE_ACCOUNT_SETTING_MODAL}`, undefined, { root: true });
	},
	setActiveMyAccountTab({ commit }: { commit: Commit }, activeTab: MY_ACCOUNT_TAB) {
		commit(`${NAMESPACE}/${MutationTypes.SET_ACTIVE_MY_ACCOUNT_TAB}`, activeTab, { root: true });
	},
	updateBio({ commit }: { commit: Commit }, bio: string) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_BIO}`, bio, { root: true });
	},
	updateDisplayName({ commit }: { commit: Commit }, displayName: string) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_DISPLAY_NAME}`, displayName, { root: true });
	},
	updateImportTemplateOption({ commit }: { commit: Commit }, openProject: TemplateImportOption) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_IMPORT_TEMPLATE_OPTION}`, openProject, { root: true });
	},
	updatePaymentStatus({ commit }: { commit: Commit }, paymentStatus: PAYMENT_STATUS | null) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_PAYMENT_STATUS}`, paymentStatus, { root: true });
	},
	updateProjectLikes({ commit }: { commit: Commit }, payload: { projectId: string; remove: boolean }) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_PROJECT_LIKES}`, payload, { root: true });
	},
	toggleCroppAvatar({ commit }: { commit: Commit }, imageFile: any) {
		commit(`${NAMESPACE}/${MutationTypes.TOGGLE_CROPP_AVATAR_MODAL}`, imageFile, { root: true });
	},
	showPortalPaymentStatusModal({ commit }: { commit: Commit }, portalPaymentStatus: UserState['showPortalPaymentStatus']) {
		commit(`${NAMESPACE}/${MutationTypes.SHOW_PORTAL_PAYMENT_STATUS_MODAL}`, portalPaymentStatus, { root: true });
	},
	setIsLoadingUserInfo({ commit }: { commit: Commit }, isLoadingUserInfo: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_LOADING_USER_INFO}`, isLoadingUserInfo, { root: true });
	},
	setUpgradeModalState({ commit }: { commit: Commit }, payload: UserState['upgradeModalState']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_UPGRADE_MODAL_STATE}`, payload, { root: true });
	},
	setShowTopUpCreditsModal({ commit }: { commit: Commit }, showTopUpCreditsModal: UserState['showTopUpCreditsModal']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_TOP_UP_CREDITS_MODAL}`, showTopUpCreditsModal, { root: true });
	},
	setToppedCreditsQuantityForPayment({ commit }: { commit: Commit }, quantity: UserState['toppedCreditsQuantityForPayment']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_TOPPED_CREDITS_QUANTITY_FOR_PAYMENT}`, quantity, { root: true });
	},
	setIsSendingMessage({ commit }: { commit: Commit }, isSendingMessage: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_SENDING_MESSAGE}`, isSendingMessage, { root: true });
	},
	setShowReferralModal({ commit }: { commit: Commit }, showReferralModal: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_REFERRAL_MODAL}`, showReferralModal, { root: true });
	},
	setShowReferralPopoverModal({ commit }: { commit: Commit }, showReferralPopoverModal: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_REFERRAL_POPOVER_MODAL}`, showReferralPopoverModal, { root: true });
	},
	setShowContactModal({ commit }: { commit: Commit }, showContactModal: ContactModalType) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_CONTACT_MODAL}`, showContactModal, { root: true });
	},
	setEmailNotifications({ commit }: { commit: Commit }, settings: UserState['emailNotifications']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_EMAIL_NOTIFICATIONS}`, settings, { root: true });
	},
	setSelectedSubscriptionForPayment({ commit }: { commit: Commit }, payload: SUBSCRIPTION) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SELECTED_SUBSCRIPTION_FOR_PAYMENT}`, payload, { root: true });
	},
	setShowAttributionExportModal({ commit }: { commit: Commit }, showAttributionDownloadModal: UserState['showAttributionDownloadModal']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_ATTRIBUTION_EXPORT_MODAL}`, showAttributionDownloadModal, { root: true });
	},
	setUserCountry({ commit }: { commit: Commit }, countryCode: string | null) {
		commit(`${NAMESPACE}/${MutationTypes.SET_USER_COUNTRY}`, countryCode, { root: true });
	},
	deductCredits({ commit }: { commit: Commit }, amount: number) {
		commit(`${NAMESPACE}/${MutationTypes.DEDUCT_CREDITS}`, amount, { root: true });
	},
	setIsFetchingCartItems({ commit }: { commit: Commit }, isFetching: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_CART_ITEMS}`, isFetching, { root: true });
	},
	setCartItems({ commit }: { commit: Commit }, cartItems: any[]) {
		commit(`${NAMESPACE}/${MutationTypes.SET_CART_ITEMS}`, cartItems, { root: true });
	},
	setCartItemsId({ commit }: { commit: Commit }, cartItemsId: string[]) {
		commit(`${NAMESPACE}/${MutationTypes.SET_CART_ITEMS_ID}`, cartItemsId, { root: true });
	},
	addCartItemId({ commit }: { commit: Commit }, payload: { itemId: string; itemType: CartItemType }) {
		commit(`${NAMESPACE}/${MutationTypes.ADD_CART_ITEMS_ID}`, payload, { root: true });
	},
	removeCartItemId({ commit }: { commit: Commit }, itemId: string) {
		commit(`${NAMESPACE}/${MutationTypes.REMOVE_CART_ITEMS_ID}`, itemId, { root: true });
	},
	clearCartItems({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.CLEAR_CART_ITEMS}`, undefined, { root: true });
	},
	setShowCartPaymentMethodModal({ commit }: { commit: Commit }, payload: { isOpen: boolean; items?: { itemId: string; itemType: CartItemType }[] }) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_CART_PAYMENT_METHOD_MODAL}`, payload, { root: true });
	},
	setShowCartPaymentStatusPopoverModal({ commit }: { commit: Commit }, cartPaymentStatus: UserState['showCartPaymentStatusPopoverModal']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_CART_PAYMENT_STATUS_POPOVER_MODAL}`, cartPaymentStatus, { root: true });
	},
	// Purchased Item
	setPurchaseItemsFilter({ commit }: { commit: Commit }, purchaseItemsFilter: PurchaseItemsFilter) {
		commit(`${NAMESPACE}/${MutationTypes.SET_PURCHASED_ITEMS_FILTER}`, purchaseItemsFilter, { root: true });
	},
	setIsFetchingPurchaseItems({ commit }: { commit: Commit }, isFetchingPurchaseItems: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_PURCHASE_ITEMS}`, isFetchingPurchaseItems, { root: true });
	},
	setPurchasedItems({ commit }: { commit: Commit }, items: PurchaseItem[], totalPages: number, currentPage: number, refresh: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_PURCHASED_ITEMS}`, { items, totalPages, currentPage, refresh }, { root: true });
	},
	resetPurchaseItems({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.RESET_PURCHASED_ITEMS}`, undefined, { root: true });
	},
	setTemporaryUserPurchasedHistory({ commit }: { commit: Commit }, itemIds: string[]) {
		commit(`${NAMESPACE}/${MutationTypes.SET_TEMPORARY_USER_PURCHASED_HISTORY}`, itemIds, { root: true });
	},
};
