import { GetterTypes, Getters, PAYMENT_STATUS, PaymentChannel, SUBSCRIPTION, UserRoles } from '@pixcap/ui-core/models/store/user.interface';
import { getPricingByUserCountry } from '@pixcap/ui-core/constants/subscription.constants';

export default {
	[GetterTypes.USER_ID]: (state) => {
		return state.personalData && state.personalData.user.userId;
	},
	[GetterTypes.GET_AVAILABLE_CREDITS]: (state) => {
		if (state.personalData?.user?.limits?.credits) {
			const { monthlyLimit, monthlyUsed, topped } = state.personalData.user.limits.credits;
			return monthlyLimit - monthlyUsed + topped;
		} else {
			return 0;
		}
	},
	[GetterTypes.GET_PRICING_FOR_USER]: (state, getters) => {
		return getPricingByUserCountry(getters[GetterTypes.OVERRIDING_PRICING_COUNTRY_CODE]);
	},
	[GetterTypes.OVERRIDING_PRICING_COUNTRY_CODE]: (state) => {
		return state.subscriptionInfo.pricingCountryCode ?? state.userCountry;
	},
	[GetterTypes.SHOW_MY_ACCOUNT_MODAL]: (state) => state.showAccountSettingModal && !state.CroppPictureModal,
	[GetterTypes.IS_PRO]: (state) => {
		const { subscription } = state.subscriptionInfo;
		return subscription == SUBSCRIPTION.PRO_MONTHLY || subscription == SUBSCRIPTION.PRO_QUARTERLY || subscription == SUBSCRIPTION.PRO_ANNUALLY;
	},
	[GetterTypes.IS_ELITE]: (state) => {
		const { subscription } = state.subscriptionInfo;
		return subscription == SUBSCRIPTION.ELITE_MONTHLY || subscription == SUBSCRIPTION.ELITE_QUARTERLY || subscription == SUBSCRIPTION.ELITE_ANNUALLY;
	},
	[GetterTypes.IS_PRO_OR_ABOVE]: (state, getters) => {
		const { paymentStatus } = state.subscriptionInfo;
		return (
			(getters[GetterTypes.IS_PRO] || getters[GetterTypes.IS_ELITE]) &&
			paymentStatus !== PAYMENT_STATUS.PENDING_RENEWAL &&
			paymentStatus !== PAYMENT_STATUS.PAYMENT_ACTION_REQUIRED &&
			paymentStatus !== PAYMENT_STATUS.PAYMENT_REQUIRES_METHOD
		);
	},
	[GetterTypes.IS_ELITE_OR_ABOVE]: (state, getters) => {
		const { paymentStatus } = state.subscriptionInfo;
		return (
			getters[GetterTypes.IS_ELITE] &&
			paymentStatus !== PAYMENT_STATUS.PENDING_RENEWAL &&
			paymentStatus !== PAYMENT_STATUS.PAYMENT_ACTION_REQUIRED &&
			paymentStatus !== PAYMENT_STATUS.PAYMENT_REQUIRES_METHOD
		);
	},
	[GetterTypes.HAS_NO_UPGRADE_OPTION]: (state) => {
		const { subscription, paymentStatus } = state.subscriptionInfo;
		const proContentAccessibleSubscriptions = [SUBSCRIPTION.ELITE_ANNUALLY, SUBSCRIPTION.ELITE_QUARTERLY, SUBSCRIPTION.ELITE_MONTHLY];
		return (
			proContentAccessibleSubscriptions.includes(subscription) &&
			paymentStatus !== PAYMENT_STATUS.PENDING_RENEWAL &&
			paymentStatus !== PAYMENT_STATUS.PAYMENT_ACTION_REQUIRED &&
			paymentStatus !== PAYMENT_STATUS.PAYMENT_REQUIRES_METHOD
		);
	},
	[GetterTypes.IS_SUBSCRIBED_WITH_STRIPE]: (state) => {
		return state.subscriptionInfo.subscription != SUBSCRIPTION.BASIC && state.subscriptionInfo.channel == PaymentChannel.STRIPE;
	},
	[GetterTypes.IS_SUBSCRIBED_WITH_PAYPAL]: (state) => {
		return state.subscriptionInfo.subscription != SUBSCRIPTION.BASIC && state.subscriptionInfo.channel == PaymentChannel.PAYPAL;
	},
	[GetterTypes.IS_SUBSCRIBED_WITH_XENDIT]: (state) => {
		return state.subscriptionInfo.subscription != SUBSCRIPTION.BASIC && state.subscriptionInfo.channel == PaymentChannel.XENDIT;
	},
	[GetterTypes.IS_SUBSCRIBED_WITH_TAZAPAY]: (state) => {
		return state.subscriptionInfo.subscription != SUBSCRIPTION.BASIC && state.subscriptionInfo.channel == PaymentChannel.TAZAPAY;
	},
	[GetterTypes.IS_SUBSCRIBED_WITH_PADDLE]: (state) => {
		return state.subscriptionInfo.subscription != SUBSCRIPTION.BASIC && state.subscriptionInfo.channel == PaymentChannel.PADDLE;
	},
	[GetterTypes.IS_FREE_TRIAL_AVAILABLE]: (state) => {
		// DISABLED FREE TRIAL
		// return !state.subscriptionInfo?.isFreeTrialInitiated && (!state.subscriptionInfo || state.subscriptionInfo?.subscription === SUBSCRIPTION.BASIC);
		return false;
	},
	[GetterTypes.IS_FREE_TRIAL_ACTIVE]: (state) => {
		return (
			state.subscriptionInfo?.isFreeTrialInitiated &&
			(state.subscriptionInfo?.paymentStatus === PAYMENT_STATUS.FREE_TRIAL ||
				state.subscriptionInfo?.paymentStatus === PAYMENT_STATUS.FREE_TRIAL_CANCELLED)
		);
	},
	[GetterTypes.SHOW_FREE_TRIAL_PAYMENT_INFO](state, getters) {
		return getters.isFreeTrialActive || getters.isFreeTrialAvailable;
	},
	[GetterTypes.IS_UNSUBSCRIBED_USER]: (state) => {
		return !state.subscriptionInfo?.subscription || state.subscriptionInfo.subscription === SUBSCRIPTION.BASIC;
	},
	[GetterTypes.IS_CANCELED_SUBSCRIPTION]: (state) => {
		return (
			state.subscriptionInfo?.paymentStatus === PAYMENT_STATUS.CANCELLED ||
			state.subscriptionInfo?.paymentStatus === PAYMENT_STATUS.FREE_TRIAL_CANCELLED
		);
	},
	[GetterTypes.SUBSCRIPTION_TEXT]: (state) => {
		const { subscription, paymentStatus } = state.subscriptionInfo;
		if (
			subscription === SUBSCRIPTION.BASIC ||
			paymentStatus == PAYMENT_STATUS.PENDING_RENEWAL ||
			paymentStatus == PAYMENT_STATUS.PAYMENT_ACTION_REQUIRED ||
			paymentStatus == PAYMENT_STATUS.PAYMENT_REQUIRES_METHOD
		)
			return 'Free member';
		else return 'Pro member';
	},
	[GetterTypes.IS_CONTRIBUTOR]: (state) => {
		return state.personalData && state.personalData.user.roles.includes(UserRoles.CONTRIBUTOR);
	},
	[GetterTypes.IS_REVIEWER]: (state) => {
		return state.personalData && state.personalData.user.roles.includes(UserRoles.REVIEWER);
	},
} as Getters;
